

import React, { useEffect, useState } from "react";
import MDBDataTableV5 from "../../CustomDataTable";
import { Spinner, Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";

import { Translate } from "../../../helpers/Translate";
import "react-datetime/css/react-datetime.css";
import MeetingModal from "./MeetingsModal";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const trimText = (text: string, len: number) => {
    return text.length > len ? text.slice(0, len) + '...' : text;
}
const GroupMeetingSlots: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    let t = new Translate();

    const [modal, setModal] = useState(false);
    const [groupmeetings, setGroupmeetings] = useState<any>([]);
    const [alltimeslots, setAlltimeslots] = useState<any>([])
    const [teachers, setTeachers] = useState<any>([]);
    const [classrooms, setClassrooms] = useState<any>([]);
    // only one
    const [joinedTimeslot_id, setJoinedTimeslot_id] = useState<any>(null);

    const retrieve_data = () => {
        axios.get(`${process.env.REACT_APP_BASEURL}student/meeting/getgroupmeetingslots.php?id=${id}`)
            .then((res: any) => {
                let hasFailed = false;
                const res_data_data = res?.data?.data;

                if (!res_data_data) {
                    hasFailed = true;
                }
                if (!res_data_data?.hasOwnProperty("groupmeetings")) {
                    hasFailed = true;
                }
                setGroupmeetings(res_data_data.groupmeetings);

                if (!res_data_data?.hasOwnProperty("teachers")) {
                    hasFailed = true;
                }
                setTeachers(res_data_data.teachers);

                if (!res_data_data?.hasOwnProperty("classrooms")) {
                    hasFailed = true;
                }
                setClassrooms(res_data_data.classrooms);

                if (!res_data_data?.hasOwnProperty("alltimeslots")) {
                    hasFailed = true;
                }
                setAlltimeslots(res_data_data.alltimeslots);

                if (!res_data_data?.hasOwnProperty("gmsgs")) {
                    hasFailed = true;
                }
                if (res_data_data?.gmsgs?.length === 1) {
                    setJoinedTimeslot_id(res_data_data?.gmsgs[0]?.groupmeetingslot_id);
                } else {
                    setJoinedTimeslot_id(null);
                }
                if (hasFailed) {
                    Swal.fire("Error while grapping Meetings!", "", "error");
                }
            });
    }
    useEffect(() => {
        try {
            retrieve_data();
        } catch {
            Swal.fire("Error while grapping Meetings!", "", "error")
                .then(() => {
                })
                .finally(() => {
                    setModal(false);
                });
        }
    }, [modal]);

    const loading = () => {
        Swal.fire({
            html: `<div style="color:blue;"> ${t.translate("Loading...")}</div>`,
            showConfirmButton: false,
        });
    };

    return (
        <>
            <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
                <Card>
                    <CardHeader>{t.translate("Meeting")}</CardHeader>
                    <hr />
                    <CardBody className="bg-light">
                        {
                            (() => {
                                if (groupmeetings?.length != 1) {
                                    return null;
                                }
                                const x = groupmeetings[0];
                                const x_id = x.id;
                                const tmp_is_online_flag = ((x?.is_online_flag ?? 0) - 0);
                                const tmp_location = (classrooms ?? [])?.find((y: any) => x?.location === y.value)?.label ?? "";
                                const teachers_json_parsedArray = JSON.parse(x.teachers_json ?? '');
                                const selectedTeachersValues = [];
                                if (teachers?.length) {
                                    for (let i = 0; i < teachers_json_parsedArray?.length; i++) {
                                        const element = teachers_json_parsedArray[i];
                                        if (!element) {
                                            continue;
                                        }
                                        const teacherFound = teachers?.find((x: any) => element === x.value);
                                        if (!teacherFound) {
                                            continue;
                                        }
                                        selectedTeachersValues?.push(teacherFound);
                                    }
                                }
                                return (
                                    <div
                                        className="row"
                                    >

                                        <div className="mt-2 col-12  ">
                                            {t.translate("subject :")}{" "} {x?.subject ?? ''}
                                        </div>

                                        <div className="mt-2 col-12  ">
                                            {t.translate("Location :")}{" "}   {tmp_is_online_flag ? t.translate("Online") : tmp_location}
                                        </div>

                                        <div className="mt-2 col-12  ">
                                            {t.translate("Teachers :")}
                                        </div>
                                        <div className=" col-12  ">
                                            <div
                                                className="row"
                                            >
                                                {
                                                    selectedTeachersValues?.map((x: any) => {
                                                        return (
                                                            <div
                                                                className="mt-1 col-12 "
                                                            >
                                                                {x.label}
                                                            </div>
                                                        );
                                                    }) ?? ""
                                                }
                                            </div>
                                        </div>

                                        <div className="mt-2 col-12  ">
                                            {t.translate("Description :")}
                                        </div>
                                        <div className="mt-1 col-12  ">
                                            {x?.description ?? ''}
                                        </div>
                                    </div>
                                );
                            })()
                        }
                    </CardBody>
                </Card>
                <div
                    style={{
                        height: "30px",
                    }}
                ></div>
                <Card>
                    <CardHeader>{t.translate("Timeslots")}</CardHeader>
                    <hr />
                    <CardBody className="bg-light">
                        <div className="table-responsive">
                            <table
                                className="table table-striped"
                            >
                                <thead>
                                    <tr>
                                        <th>{t.translate("#")}</th>
                                        <th>{t.translate("Start date")}</th>
                                        <th>{t.translate("Duration")}</th>
                                        <th>{t.translate("Available Seats")}</th>
                                        <th>{t.translate("Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        alltimeslots.map((timeslot: any, key: any) => {
                                            const timeslot_id = timeslot.id;
                                            if (timeslot_id === undefined) {
                                                return null;
                                            }

                                            const date = new Date(timeslot?.startdate);

                                            // Extract individual components
                                            const year = date.getFullYear();
                                            const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
                                            const day = date.getDate();
                                            const hours = date.getHours();
                                            const minutes = date.getMinutes();

                                            // Optional: Format hours to be 2 digits
                                            const formattedHours = hours < 10 ? `0${hours}` : hours;
                                            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

                                            // Format the date
                                            const formattedDate = `${day}/${month}/${year} - ${formattedHours}:${formattedMinutes}`;

                                            const available = (groupmeetings[0]?.capacity ?? 0) - (timeslot?.booked ?? 0);

                                            return (
                                                <tr key={key + 1}>
                                                    <td>{key + 1}</td>
                                                    <td>
                                                        {
                                                            formattedDate
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            timeslot?.duration ?? 0
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            available
                                                        }
                                                    </td>
                                                    <td>
                                                        {

                                                            (joinedTimeslot_id === timeslot_id) ?
                                                                (
                                                                    <button
                                                                        className=" btn btn-danger "
                                                                        onClick={() => {
                                                                            Swal.fire({
                                                                                title: t.translate("Do you want to cancel this meeting?"),
                                                                                showDenyButton: true,
                                                                                confirmButtonText: t.translate(`Confirm`),
                                                                                denyButtonText: t.translate(`Cancel`),
                                                                            }).then((result) => {
                                                                                if (result.isConfirmed) {
                                                                                    loading();
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_BASEURL}student/meeting/cancelgroupmeetingslot.php`, {
                                                                                            groupmeeting_id: id,
                                                                                            groupmeetingslot_id: timeslot_id,
                                                                                        }).then((response: any) => {
                                                                                            const response_data_data = response?.data?.data;
                                                                                            if ((response_data_data?.flag ?? 0) - 0) {
                                                                                                Swal.fire(t.translate('Success'), t.translate('Canceled successfully'), 'success').then(() => {
                                                                                                    retrieve_data();
                                                                                                });
                                                                                            } else {
                                                                                                Swal.fire(t.translate('Failed to cancel'), "", 'error').then(() => { })
                                                                                            }
                                                                                        }).catch((error) => {
                                                                                            // console.log(error.response.data.msg);
                                                                                            Swal.fire(t.translate('Failed to cancel'), t.translate(error.response.data.msg), 'error').then(() => {
                                                                                            })
                                                                                        })
                                                                                } else if (result.isDenied) {
                                                                                }
                                                                            });
                                                                        }}
                                                                    >
                                                                        {
                                                                            t.translate("Cancel")
                                                                        }
                                                                    </button>
                                                                )
                                                                :
                                                                (
                                                                    <button
                                                                        className=" btn btn-primary "
                                                                        disabled={!available}
                                                                        onClick={() => {
                                                                            Swal.fire({
                                                                                title: t.translate(`Do you want to join this meeting${(null === joinedTimeslot_id) ? "" : " instead"}?`),
                                                                                showDenyButton: true,
                                                                                confirmButtonText: t.translate(`Confirm`),
                                                                                denyButtonText: t.translate(`Cancel`),
                                                                            }).then((result) => {
                                                                                if (result.isConfirmed) {
                                                                                    loading();
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_BASEURL}student/meeting/joingroupmeetingslot.php`, {
                                                                                            groupmeeting_id: id,
                                                                                            groupmeetingslot_id: timeslot_id,
                                                                                        }).then((response: any) => {
                                                                                            const response_data_data = response?.data?.data;
                                                                                            if ((response_data_data?.flag ?? 0) - 0) {
                                                                                                Swal.fire(t.translate('Success'), t.translate('Joined successfully'), 'success').then(() => {
                                                                                                    retrieve_data();
                                                                                                });
                                                                                            } else {
                                                                                                Swal.fire(t.translate('Failed to join'), "", 'error').then(() => { })
                                                                                            }
                                                                                        }).catch((error) => {
                                                                                            // console.log(error.response.data.msg);
                                                                                            Swal.fire(t.translate('Failed to join'), t.translate(error.response.data.msg), 'error').then(() => {
                                                                                            })
                                                                                        })
                                                                                } else if (result.isDenied) {
                                                                                }
                                                                            });
                                                                        }}
                                                                    >
                                                                        {
                                                                            t.translate("Request to join")
                                                                        }
                                                                    </button>
                                                                )

                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </div>
            {/* {modal ?
                <MeetingModal
                    id={id}
                    closeModal={() => { setModal(false); }}
                ></MeetingModal> : null} */}
        </>
    );
};


export default GroupMeetingSlots;