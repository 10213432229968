import React, {  useState, useEffect } from "react";
import {  Form } from '../../MyComponents/myForms';
import '../../../css/login.css';
import { Translate } from '../../../helpers/Translate';

import {  useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { CountryDropdown as Countries } from 'react-country-region-selector'
import axios from "axios";
import { url } from "../../../store/initialStatus";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { parseJwt } from "../../../helpers/func";




var fnSubmit = true;
var mnSubmit = true;
var lnSubmit = true;
var eSubmit = true;
var ssnSubmit = true;
var occSubmit = true;



const EditProfile = (props: any) => {
    const [state, setState] = useState<any>({
        firstname: "",
        middlename: "",
        lastname: "",
        username: "",
        ssn: "",
        ocupation: "",
        country: "",
        mobile: "",
        education_level: "",

        countryName: null,
        image: null,

        phone: "Must be Mobile Number ex: 0123456789101",
        email: "",

    });
    function getThumbnail(file: any) {

        let fileReader: any = new FileReader();
        if (file.type.match('image')) {
            fileReader.onload = function () {
                setPrveiewImg(fileReader.result)
                // $('#blah').attr('src', fileReader.result);
                // console.log(fileReader.result);
            };
            fileReader.readAsDataURL(file);
        } else {
            fileReader.onload = function () {
                let blob = new Blob([fileReader?.result], {
                    type: file.type
                });
                let video = document.createElement('video');

                const snapImage = () => {
                    let canvas: any = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                    let image = canvas.toDataURL();
                    let success = image.length > 100000;
                    // $('#blah').attr('src', image);
                    setPrveiewImg(image)


                    // Thumbnail --- image  base64
                    if (success) {
                        $("#video_image").val(image);
                    }
                    return success;
                };
                let url = URL.createObjectURL(blob);
                let timeupdate = () => {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                        video.pause();
                    }
                };
                video.addEventListener('loadeddata', function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                    }
                });

                video.addEventListener('timeupdate', timeupdate);
                video.preload = 'metadata';
                video.src = url;
                // Load video in Safari / IE11
                video.muted = true;
                video.play();
            };
            fileReader.readAsArrayBuffer(file);
        }


    }
    const guardian = useSelector((state: any) => state.Parent.guardian)
        const [previewImg, setPrveiewImg] = useState<any>("");
            const [selectedFile, setSelectedFile] = useState<any>(null);
const t = new Translate();
        
    
    const dispatch = useDispatch();
    useEffect(() => {
        const token: any = Cookies.get("guardiantoken");
        let parsedToken = parseJwt(token);
        let guardian = parsedToken.data.guardian
        if (guardian.id) {
            axios.post(`${url}guardian/getguardian.php`, { id: guardian?.id }).then((res: any) => {
                setState(res.data.data)
            })
        }
    }, [])

    const onSelectCountry = (event: any) => {

        setState((prevState: any) => ({ ...prevState, country: event }));
    }

    const handlePhone = (e: any) => {

        let value = e.target.value
        setState((prevState: any) => ({ ...prevState, phone: value }));


    }


    const navigate = useNavigate();


    const onDrop = (picture: any) => {

        setState((prevState: any) => ({ ...prevState, image: picture[0] }));

    }

    const onSaveProvfile = () => {
        if (fnSubmit == true && mnSubmit == true && lnSubmit == true &&
            eSubmit == true && ssnSubmit == true && occSubmit == true) {
            const formData = new FormData();
            // Update the formData object
            for (const property in state) {
                formData.append(
                    property,
                    state[property],
                );
            }
        formData.append('image', selectedFile)

            Swal.showLoading();
            axios.post(`${url}guardian/updateguardian.php`, formData).then((res: any) => {
                setState(res.data.data)
                Swal.hideLoading();
                Swal.fire({
                    title: "Success",
                    text: "Profile updated successfully",
                    icon: "success"
                }).then(() => {
                    navigate({ pathname: "/dashboard/viewprofile" });

                })

            })
        }
        else {
            Swal.fire({
                title: "Would you please check your data?",
                text: "Please enter valid inputs to be able to edit yor data",
                icon: "warning"
            })

        }
    }

    const onCancel = () => {
        // this.props.ClearParent()
        // this.props.Navigate("/dashboard/viewprofile")
    }

    const handleChange = (field: any, e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setState((prevState: any) => ({ ...prevState, [name]: value }));
    }

    return (


        <div className=" rounded bg-white mt-5 mb-5">

            <Form >

                <div className="row">
               
                    
                    <div className="col-md-12 border-right">
                        <div className="p-3 py-5">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="text-right">Profile Settings</h4>
                            </div>
                           
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <label >First Name: </label>
                                    <input type="text" className="form-control"
                                        id="firstname"
                                        name="firstname"
                                        placeholder="Place your ssn here ^-^"
                                        value={state.firstname} //Optional.[String].Default: "".
                                        onChange={(e: any) => {
                                            let value = e.target.value
                                            setState((prevState: any) => ({ ...prevState, firstname: value }));

                                        }}
                                        onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                        required
                                        pattern="[A-Za-z ا-ي]{3,100}"
                                    />


                                </div>

                                <div className="col-md-6">
                                    <label >Middle Name: </label>
                                    <input type="text" className="form-control"
                                        id="middlename"
                                        name="middlename"
                                        placeholder="Place your ssn here ^-^"
                                        value={state.middlename} //Optional.[String].Default: "".
                                        onChange={(e: any) => {
                                            let value = e.target.value
                                            setState((prevState: any) => ({ ...prevState, middlename: value }));
                                        }}
                                        onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                        required
                                        pattern="[A-Za-z ا-ي]{3,100}"
                                    />
                                    <br />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <label >Last Name: </label>
                                    <input className="form-control" id="lastname" placeholder="Place your ssn here ^-^"
                                        name="lastname"
                                        value={state.lastname} //Optional.[String].Default: "".
                                        onChange={(e: any) => {
                                            let value = e.target.value
                                            setState((prevState: any) => ({ ...prevState, lastname: value }));
                                        }}
                                        onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                        required
                                        pattern="[A-Za-z ا-ي]{3,100}"
                                    />
                                    <br />
                                </div>
                                <div className="col-md-6">
                                    <label >Email: </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="email"
                                        name="email"
                                        placeholder="Place your email here ^-^"
                                        value={state.username} //Optional.[String].Default: "".
                                        onChange={(e: any) => {
                                            let value = e.target.value
                                            setState((prevState: any) => ({ ...prevState, username: value }));
                                        }}
                                        onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                        required
                                        pattern="[A-Za-z0-9 \- _ \.]+@[A-Za-z0-9 \- _ \]+\.[A-Za-z]{1,5}"
                                    />


                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <label >National ID / SSN: </label>
                                    <input
                                        type="number"
                                        id="ssn"
                                        name="ssn"
                                        className="form-control"
                                        placeholder="Place your ssn here ^-^"
                                        value={state.ssn} //Optional.[String].Default: "".
                                        onChange={(e: any) => {
                                            let value = e.target.value
                                            setState((prevState: any) => ({ ...prevState, ssn: value }));
                                        }}
                                        onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                        pattern="[0-9 \w]{5,14}"
                                        required
                                    />
                                    <br />


                                </div>
                                <div className="col-md-6"><label >Mobile: </label>
                                    <input type="number"
                                        placeholder="Enter mobile number"
                                        value={state.mobile}
                                        className="form-control"
                                        name="mobile"
                                        onChange={(e: any) => {

                                            let value = e.target.value
                                            setState((prevState: any) => ({ ...prevState, mobile: value }));
                                        }}
                                        required
                                        pattern="[0-9 \-]{8,15}"
                                    />

                                </div>

                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <label >Occupation: </label>
                                    <input
                                        type="text"
                                        id="ocupation"
                                        className="form-control"
                                        name="ocupation"
                                        placeholder="Pleae mention your ocupation"
                                        value={state.ocupation} //Optional.[String].Default: "".
                                        onChange={(e: any) => {
                                            let value = e.target.value
                                            setState((prevState: any) => ({ ...prevState, ocupation: value }));
                                        }}
                                        onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                        required
                                    />
                                    <br />
                                </div>

                                <div className="col-md-6">
                                    <label> Highest Education Level:
                                    </label>

                                        <select
                                            className="form-control"
                                            aria-label="Default select"
                                            name="education_level"
                                            value={state.education_level}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                setState((prevState: any) => ({ ...prevState, education_level: value }));
                                            }}
                                        >
                                            <option value="">Choose Education Level</option>
                                            <option value="PHd.">Phd.</option>
                                            <option value="MSc.">Msc. </option>
                                            <option value="BSc.">Bsc. </option>
                                        </select>

                                </div>

                            </div>

                            <div className="row mt-3">
                                <div className="col-md-6"><label >Country: </label> <br />
                                    <Countries
                                        name="country"
                                        value={state.country}
                                        onChange={(e: any) => onSelectCountry(e)}
                                        classes="form-control"
                                    />
                                </div>

                            </div>
                           
                        <div  className="col-md-6 p-0 mt-3">
                            <label> {t.translate("Profile picture")}</label>
                            <input
                                type="file"
                                className="form-control"
                                name="blogmedia"
                                onChange={(e: any) => {
                                    // console.log(e.target.files[0]);
                                    setSelectedFile(e.target.files[0])
                                    getThumbnail(e.target.files[0])
                                }}
                            />
                        </div>

                        {previewImg &&
                            <div style={{ width: "100%" }} className="mt-3">

                                <img
                                    alt="Card cap"
                                    src={previewImg}
                                    width="30%"
                                />

                            </div>
                        }
                         {!previewImg && state.driveid && <div className="mt-3">
                            <img
                                alt="Card cap"
                                src={ state.driveid?.includes("https://") ? state.driveid  :`http://drive.google.com/uc?export=view&id=${state.driveid}`}
                                width="30%"
                               
                            />
                        </div>}
                            <div className="mt-5 text-center">
                                <button className="btn btn-primary profile-button" onClick={onSaveProvfile}
                                    type="button">Save Profile</button></div>
                        </div>
                    </div>

                </div>

            </Form>
        </div>

    )

}



export default EditProfile;

