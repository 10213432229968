import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actionCreators as StudnetCreator } from "../../store/student";
import { Card, CardBody, CardHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import { PrintGrades } from '../../utils/helperFunc';
import Select from 'react-select';
import { Translate } from '../../helpers/Translate';

export default function ProgressReport() {
    const dispatch: Dispatch<any> = useDispatch();
    const { id } = useParams<{ id?: string }>();
    const students = useSelector((state: any) => state.Student.students);
    const student = students.find((st: any) => st.id == id);
    const guardian = useSelector((state: any) => state.Parent.guardian)
    const [progressData, setProgessData] = useState([])
    const [yearId, setYearId] = useState("")
    const [academicYearOptions, setAcademicYearOptions] = useState<{ value: string, label: string }[]>([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [academicYearData, setAcademicYearData] = useState([])
    const [gradingScales ,setGradingScales] = useState([])
    const [enrollmentData, setEnrollmentData] = useState([])
    const t = new Translate()
    useEffect(() => {

        dispatch(StudnetCreator.GetStudents({ id: guardian.id }));

        try {
            axios.post(`${process.env.REACT_APP_BASEURL}student/getcourses.php`, { stId: id }).then(
                (res: any) => {

                    if (res.data.data.errorMsg) {
                        setErrorMsg(res.data.data.errorMsg)
                    } else {
                        setGradingScales(res.data.data.grading_scales)
                        setProgessData(res.data.data.grades);
                    }

                    setAcademicYearData(res.data.data.academicyears)
                    setYearId(res.data.data.year_id)
                    const newOptions: { value: string, label: string }[] = res.data.data.academicyears?.map((year: any) => {
                        return { value: year.id, label: `${year.academicyear}` };
                    });
                    setAcademicYearOptions(newOptions)

                }
            )
        } catch {
            Swal.fire(t.translate('Error while getting courses!'), '', 'error');
        }
    }, []);



    const roundNumber = (number: any) => {
        let modifiedNumber: any = number;
        if (number % 1 != 0) {
            modifiedNumber = parseFloat(number).toFixed(2)
        }

        if (isNaN(modifiedNumber)) {
            return "NA"
        } else {
            return modifiedNumber
        }

    }


    function getGradeLable(grade:any )
    {
      for (let index = 0; index < gradingScales.length; index++) {
        let grading_scale :any = gradingScales[index];
        if (grade >= grading_scale.from && grade <= grading_scale.to) return grading_scale.lettergrade;
        
      }
  
        return "NA"
    }


    const handlePrintGrades = (id: any, year_id: any) => {
        PrintGrades(id, year_id);
    }
    const handleSelectyear = (e: any) => {
        setYearId(e.value)
        axios.post(`${process.env.REACT_APP_BASEURL}student/getcourses.php`, { stId: id, year_id: e.value }).then(
            (res: any) => {
                if (res.data.data.errorMsg) {
                    setErrorMsg(res.data.data.errorMsg)
                } else {

                    setProgessData(res.data.data.grades);
                }
                setAcademicYearData(res.data.data.academicyears)
                setYearId(res.data.data.year_id)
                const newOptions: { value: string, label: string }[] = res.data.data.academicyears?.map((year: any) => {
                    return { value: year.id, label: `${year.academicyear}` };
                });
                setAcademicYearOptions(newOptions)


            }
        )
    }
    return (
        <div className="row">
            <div className="text-danger">
                {(student) ? <h3 className="mb-5">{student.fullname} : <small style={{ fontStyle: 'italic' }}>{student.id} : {student.grade}</small></h3> : <></>}
            </div>
            <Card className="w-100">
                <CardHeader>



                    <h4 className="title">{t.translate("Progress Report")}</h4>
                </CardHeader>
                <CardBody className="all-icons">

                    <button style={{ marginBottom: "2%" }} className="btn btn-primary" onClick={() => { handlePrintGrades(id, yearId) }}>{t.translate("Print")}</button>

                    <Select
                        closeMenuOnSelect={true}
                        options={academicYearOptions}
                        onChange={(e) => {
                            handleSelectyear(e);
                        }}
                        value={academicYearOptions.find((f: any) => { return f.value == yearId })}
                    />

                    {errorMsg == "" ? (
                        <>
                            <div className="table-responsive">

                                <table id="dataTable" className="  table table-striped" >
                                    <thead>
                                        <tr>
                                            <th>{t.translate("Course")}</th>
                                            <th>{t.translate("First Quarter")}</th>
                                            <th>{t.translate("Second Quarter")}</th>
                                            <th>{t.translate("First Semester")}</th>
                                            <th>{t.translate("Third Quarter")}</th>
                                            <th>{t.translate("Fourth Quarter")}</th>
                                            <th>{t.translate("Second Semester")}</th>
                                            <th >{t.translate("Final")}</th>
                                            <th ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {progressData?.map((data: any, index: any) => {

                                            return (<tr key={index} id={index}>
                                                <td>{data.name}</td>
                                                <td>{roundNumber(data.quarters.find((quarter: any) => { return quarter.quarter.includes("Quarter 1") })?.grade ?? "NA")}</td>
                                                <td>{roundNumber(data.quarters.find((quarter: any) => { return quarter.quarter.includes("Quarter 2") })?.grade ?? "NA")}</td>
                                                <td>{roundNumber(data.firstsemseter)}</td>
                                                <td>{roundNumber(data.quarters.find((quarter: any) => { return quarter.quarter.includes("Quarter 3") })?.grade ?? "NA")}</td>
                                                <td>{roundNumber(data.quarters.find((quarter: any) => { return quarter.quarter.includes("Quarter 4") })?.grade ?? "NA")}</td>
                                                <td>{roundNumber(data.secondsemester)}</td>
                                                <td>{roundNumber(data.total)}</td>
                                                <td>{getGradeLable(roundNumber(data.total))}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>

                            </div>

                            <table className="table table-bordered ">
                                <tbody>
                                    <tr>
                                        {
                                            gradingScales?.map((gradingScale:any)=>{
                                                
                                                return (
                                                    <td key={gradingScale.id} >
                                                        <p ><span >{gradingScale.from} - {gradingScale.to} = {gradingScale.lettergrade}</span></p>
                                                    </td>
                                                )
                                            })
                                        }
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    ) : (errorMsg)

                    }





                </CardBody>
            </Card>
        </div>


    );
}