import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MedicalRecord from './MedicalRecord'
import Chromebook from './ChromeBook';
import { Row, Col, Card, CardImg, CardBody, CardTitle, ModalBody, ModalFooter, Modal, ModalHeader, Form, Input, FormGroup } from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import PanelHeader from '../PanelHeader/PanelHeader';
import "./Home.css";
import { Translate } from '../../../../helpers/Translate';
import axios from 'axios';
import Swal from 'sweetalert2';
import img12 from "./assets/img/menu.png"
import img2 from "./assets/img/ivy_transportation.png";
import finance from "./assets/img/finance.jpg";
import { url } from '../../../../store/initialStatus';
import Cookies from 'js-cookie';

const t = new Translate();




const Home = () => {

  const navigate = useNavigate();
  const height = "100Px";
  const transjwt: any = useSelector(
    (state: any) => state.Parent.transjwt
  );
  const Parent: any = useSelector(
    (state: any) => state.Parent
  );


  const [lms_token, setLmsToken] = useState(Cookies.get("lms_jwt"))

  const [studentsRequiredDocs, setStudentsRequiredDocs] = useState([]);
  const [requiredDocsModal, setRequiredDocsModal] = useState(false);

  const [commenRequiredDocs, setCommenRequiredDocs] = useState([])
  const [uploadedDocs, setUploadedDocs] = useState<any>([])

  if (Parent.guardian?.guardian_id != undefined) {
    axios.get(`${url}auth/checkifhold.php?guardian_id=${Parent.guardian?.guardian_id}`).then((res) => {
      if (res.data.data == 1) {

        Swal.fire({
          title: t.translate("You have an overdue installment that needs to be paid."),
          html: t.translate("Please note that your account will remain on hold until all overdue installments are paid"),

          showCancelButton: true,
          confirmButtonText: t.translate("Click here to pay"),

          icon: "warning",
        }).then((res) => {
          if (res.isConfirmed) {
            navigate({ pathname: "/dashboard/schoolfees" });
          } else {

          }

        })
      }
    })
  }

  useEffect(() => {

    if (Parent.guardian.guardian_id != undefined) {
      axios.get(`${url}guardian/checkrequireddocs.php?guardian_id=${Parent.guardian.guardian_id}`).then((res) => {

        if (res.data.data?.length > 0) {
          try {
            setRequiredDocsModal(true)
            setStudentsRequiredDocs(res.data.data)
            let tempFiles: any = [];
            res.data.data?.forEach((studentsFiles: any) => {
              studentsFiles?.required_docs?.forEach((file: any) => {
                let foundIndex = tempFiles?.findIndex((f: any) => f.id == file.id)
                if (foundIndex == -1) {
                  if (file.commenfile == 1)
                    tempFiles.push(file)
                }
              });
            })
            setCommenRequiredDocs(tempFiles)
          } catch (error) {

          }

        }
      })
    }
  }, [])
  useEffect(() => {

    if (lms_token == undefined)
      axios.get(`${url}guardian/getlmstoken.php`).then((res) => {
        Cookies.set("lms_jwt", res.data.data, { sameSite: 'none', secure: true, expires: 3 });

        setLmsToken(res.data.data)

      })

  }, [])



  const handleSubmit = (e: any) => {
    e.preventDefault()
    Swal.showLoading();
    const blogData = new FormData();

    uploadedDocs.forEach((doc: any) => {

      blogData.append(`requireddocument_${doc.id}`, doc.uploadedFile)
      blogData.append(`parent_id`, Parent.guardian.guardian_id)
    });
    // blogData.append('title', formData.title)
    // blogData.append('body', formData.body)
    // blogData.append('id', formData.id)
    // blogData.append('parent', guardian.id)

    axios.post(`${url}guardian/uploadrequireddocs.php`, blogData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res: any) => {
      Swal.fire({
        title: t.translate("Documents uploaded successfully"),
        icon: "success",
      })
      setRequiredDocsModal(false)
    })
  }


  return (
    <>

      <MedicalRecord />

      <Chromebook />
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col lg={3} md={4} sm={6} xs={6}>
            <a className="" style={{ cursor: "pointer" }} target='_blank' href={`${process.env.REACT_APP_LMS_BASURL}include/store_parent_token.php?token=${lms_token}&emaildomain=ivyis.org`}>

              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={"https://staff.ivyis.org/images/ivylms.jpg"} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("IVY's LMS")}
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={3} md={4} sm={6} xs={6}>
            <NavLink to={"/dashboard/cafeteria"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={img12} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Cafeteria")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
          <Col lg={3} md={4} sm={6} xs={6}>
            <a href={`https://transportation.ivyis.org/?hash=` + transjwt} target="_blank" className="">
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={img2} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Transportation")}
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={3} md={4} sm={6} xs={6}>
            <NavLink to={"/dashboard/finance"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={finance} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Smart Wallet")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <br />
      </div>



      {/* // Required docs modal  */}
      <Modal className="sizeModal" isOpen={requiredDocsModal}>
        <ModalHeader> {t.translate("Please upload required documents")}</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>




            <div style={{}}>

              {
                commenRequiredDocs.map((requiredDoc: any, index: number) => {

                  return (
                    <div key={index} className='mt-4'>
                      <label style={{ fontWeight: "bold" }}> {requiredDoc?.displayname} </label>
                      <input
                        type="file"
                        className="form-control"
                        name={`selected_required_document_${requiredDoc?.id}`}
                        onChange={(e: any) => {
                          let uploadedDoc = { id: requiredDoc.id, uploadedFile: e.target.files[0], iscommen: requiredDoc.commenfile, filetype: requiredDoc.filetype, "student_id": "" }
                          let tempuploadedDocs = [...uploadedDocs]
                          let foundIndex = tempuploadedDocs?.findIndex((doc: any) => doc.id == requiredDoc.id)
                          if (foundIndex == -1) {
                            tempuploadedDocs.push(uploadedDoc)
                          } else {
                            tempuploadedDocs[foundIndex].uploadedFile = e.target.files[0]
                          }
                          setUploadedDocs(tempuploadedDocs)
                        }}
                      />
                    </div>
                  )
                })
              }

            </div>
            <div style={{ marginBottom: "2%", marginTop: "2%", fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
              <div>

                {t.translate("Students required documents")}

              </div>
            </div>
            {
              studentsRequiredDocs.map((studentDocs: any, index: number) => {
                if (studentDocs?.required_docs?.filter((f: any) => f.commenfile != 1)?.length > 0) {

                  return (
                    <div key={index}>

                      <div className='text-center'>
                        <label style={{ fontWeight: "bold", marginTop: "20px" }}> {t.translate("Name")} : {studentDocs?.student?.fullname}</label>
                      </div>
                      {
                        studentDocs?.required_docs?.filter((f: any) => f.commenfile != 1).map((requiredDoc: any, index: number) => {

                          return (
                            <div key={index} className='mt-4'>


                              <label style={{ fontWeight: "bold" }}> {requiredDoc?.displayname} </label>
                              <input
                                type="file"
                                className="form-control"
                                name={`required_document_${studentDocs?.student?.id}_${requiredDoc?.id}`}
                                onChange={(e: any) => {
                                  let uploadedDoc = { id: `${studentDocs?.student?.id}_${requiredDoc?.id}`, uploadedFile: e.target.files[0], iscommen: requiredDoc.commenfile, filetype: requiredDoc.filetype, "student_id": studentDocs?.student?.id }
                                  let tempuploadedDocs = [...uploadedDocs]
                                  let foundIndex = tempuploadedDocs?.findIndex((doc: any) => doc.id == `${studentDocs?.student?.id}_${requiredDoc?.id}`)
                                  if (foundIndex == -1) {
                                    tempuploadedDocs.push(uploadedDoc)
                                  } else {
                                    tempuploadedDocs[foundIndex].uploadedFile = e.target.files[0]
                                  }
                                  setUploadedDocs(tempuploadedDocs)
                                }}
                              />

                            </div>
                          )
                        })
                      }
                      <hr></hr>
                    </div>
                  )
                } else {
                  return null;
                }

              })
            }









            <Input
              type="submit"

              className="btn btn-primary mt-3"
              style={{ backgroundColor: "blue" }}
              value={t.translate("Submit")}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={() => setRequiredDocsModal(false)}>
            {t.translate("Cancel")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Home;