
import React, { useEffect, useState } from "react";
import MDBDataTableV5 from "../../CustomDataTable";
import { Spinner, Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";

import { Translate } from "../../../helpers/Translate";
import "react-datetime/css/react-datetime.css";
import MeetingModal from "./MeetingsModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { wrapper_get } from "../../../helpers/call_wrapper_funcs";

const trimText = (text: string, len: number) => {
    return text.length > len ? text.slice(0, len) + '...' : text;
}
const GroupMeetings: React.FC = () => {
    const navigate = useNavigate();
    let t = new Translate();
    const defaultTable = {
        columns: [
            {
                label: "#",
                field: "index",
            },
            {
                label: t.translate("Subject"),
                field: "subject",
            },
            {
                label: t.translate("Description"),
                field: "description",
            },
            {
                label: t.translate("Teachers"),
                field: "teachers",
            },
            {
                label: t.translate("Location"),
                field: "location",
            },
            {
                label: t.translate("Timeslots"),
                field: "timeslots",
            },
            {
                label: t.translate("Action"),
                field: "action",
            },
        ],
        rows: [
            {
                id: <Spinner color="info" />,
                stdids: <Spinner color="info" />,
                subject: <Spinner color="info" />,
                type: <Spinner color="info" />,
                teacher: <Spinner color="info" />,
                location: <Spinner color="info" />,
                status: <Spinner color="info" />,
                view: <></>,
            },
        ],
    }
    const [intDataTable, setIntDataTable] = useState(defaultTable);
    //   var now = new Date();
    //   now.setDate(now.getDate() + 1);
    //   now.setHours(10);
    //   now.setMinutes(0);
    //   now.setMilliseconds(0);

    const [id, setId] = useState(null);
    const [modal, setModal] = useState(false);
    const [groupmeetings, setGroupmeetings] = useState<any>([]);
    const [alltimeslots, setAlltimeslots] = useState<any>([])
    const [teachers, setTeachers] = useState<any>([]);
    const [classrooms, setClassrooms] = useState<any>([]);

    useEffect(() => {
        try {
            axios.get(`${process.env.REACT_APP_BASEURL}student/meeting/getgroupmeetings.php`)
                .then((res: any) => {
                    let hasFailed = false;
                    const res_data_data = res?.data?.data;

                    if (!res_data_data) {
                        hasFailed = true;
                    }
                    if (!res_data_data?.hasOwnProperty("groupmeetings")) {
                        hasFailed = true;
                    }
                    setGroupmeetings(res_data_data.groupmeetings);

                    if (!res_data_data?.hasOwnProperty("teachers")) {
                        hasFailed = true;
                    }
                    setTeachers(res_data_data.teachers);

                    if (!res_data_data?.hasOwnProperty("classrooms")) {
                        hasFailed = true;
                    }
                    setClassrooms(res_data_data.classrooms);

                    if (!res_data_data?.hasOwnProperty("alltimeslots")) {
                        hasFailed = true;
                    }
                    setAlltimeslots(res_data_data.alltimeslots);

                    if (hasFailed) {
                        Swal.fire("Error while grapping Meetings!", "", "error");
                    }
                });
        } catch {
            Swal.fire("Error while grapping Meetings!", "", "error")
                .then(() => {
                })
                .finally(() => {
                    setModal(false);
                });
        }
    }, [modal]);


    const handleOpen = () => {
        setId(null);
        setModal(true);
    };

    const handleview = (id: any) => {
        setId(id);
        setModal(true);
    };


    return (
        <>
            <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
                <Card>
                    <CardHeader>{t.translate("Meetings")}</CardHeader>
                    <hr />
                    <CardBody className="bg-light">
                        <MDBDataTableV5
                            hover
                            responsive
                            borderless={false}
                            bordered
                            striped
                            entriesOptions={[5, 20, 25]}
                            entries={5}
                            pagesAmount={4}
                            data={{
                                ...defaultTable, rows: ((groupmeetings ?? [])?.map((x: any, index: number) => {
                                    const x_id = x.id;
                                    const tmp_is_online_flag = ((x?.is_online_flag ?? 0) - 0);
                                    const tmp_location = (classrooms ?? [])?.find((y: any) => x?.location === y.value)?.label ?? "";
                                    
                                    
                                    const timeslots: any = new Set();

                                    for (let i = 0; i < alltimeslots?.length; i++) {
                                        const element = alltimeslots[i];
                                        // groupmeeting_id
                                        if (x_id != element?.groupmeeting_id) {
                                            continue;
                                        }
                                        const startdate_slice = element?.startdate?.slice(0, 10);
                                        timeslots.add(startdate_slice)
                                    }
                                    // Convert the Set to an array
                                    const dateArray = [...timeslots];

                                    // Sort the array of date strings
                                    const sortedDateArray = dateArray.sort();
                                    const teachers_json_parsedArray = JSON.parse(x.teachers_json ?? '');
                                    const selectedTeachersValues = [];
                                    if (teachers?.length) {
                                        for (let i = 0; i < teachers_json_parsedArray?.length; i++) {
                                            const element = teachers_json_parsedArray[i];
                                            if (!element) {
                                                continue;
                                            }
                                            const teacherFound = teachers?.find((x: any) => element === x.value);
                                            if (!teacherFound) {
                                                continue;
                                            }
                                            selectedTeachersValues?.push(teacherFound);
                                        }
                                    }
                                    return {
                                        ...x,
                                        index: 1 + index,
                                        teachers: (
                                            <div
                                                className="text-center"
                                                style={{
                                                    // color: "red",
                                                }}
                                            >
                                                {
                                                    selectedTeachersValues?.map((x: any, xi: number) => {
                                                        return (
                                                            <div
                                                                className="p-2 m-1"
                                                                key={x?.value ?? xi}
                                                                style={{
                                                                    border: "1px solid blue",
                                                                    borderRadius: "10px",
                                                                }}
                                                            >
                                                                {x.label}
                                                            </div>
                                                        );
                                                    }) ?? ""
                                                }
                                            </div>
                                        ),
                                        location: tmp_is_online_flag ? t.translate("Online") : tmp_location,
                                        description: (
                                            <div
                                                style={{
                                                    width: "35vw",
                                                }}
                                            >
                                                {
                                                    trimText(x?.description ?? '', 200)
                                                }
                                            </div>
                                        ),
                                        timeslots: (
                                            <div
                                                style={{
                                                    width: "fit-content",
                                                }}
                                            >
                                                {
                                                    sortedDateArray.map((y: any) => (
                                                        <div
                                                            className="text-nowrap"
                                                        >{y}</div>
                                                    ))
                                                }
                                            </div>
                                        ),
                                        action: (
                                            <button
                                                className=" btn btn-primary "
                                                onClick={() => {

                                                    navigate(`/dashboard/groupmeetings/${x_id}`);
                                                }}
                                            >
                                                {
                                                    t.translate("View")
                                                }
                                            </button>
                                        ),
                                    }
                                }))
                            }}
                            fullPagination
                            searchTop
                            searchBottom={false}
                        />
                    </CardBody>
                </Card>
            </div>
            {/* {modal ?
                <MeetingModal
                    id={id}
                    closeModal={() => { setModal(false); }}
                ></MeetingModal> : null} */}
        </>
    );
};


export default GroupMeetings