import React from 'react';
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
  function Date(props: any) {

return(
    <DatePicker
    style={{
        width: "100%",
        boxSizing: "border-box",
        height: "35px"
      }}
      containerStyle={{
        width: "100%"
      }}
      calendarPosition="bottom-center"
        multiple
        value={props.values}
        onChange={props.setValues}
        plugins={[
         <DatePanel  />
        ]}
      />

)
  }
  export default Date;

