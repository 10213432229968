import React, { useEffect, useLayoutEffect, useState } from "react";
import { DropDownNav } from "../MyComponents/MyNavComponents";
import { NavLink, useNavigate } from "react-router-dom";
import { Nav } from "reactstrap";
import { Translate } from "../../helpers/Translate";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../store/student";
import { UncontrolledCollapse } from "reactstrap";
import { Button } from 'reactstrap';
import Icon from "@material-ui/core/Icon";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";


interface MenuItem {
  MenuItemId: number;
  Name: string;
  MenuItems: Array<MenuItem>;
  Pages: Array<Page>;
}
interface Page {
  PageId: number;
  Name: string;
}
function getFirstWord(str: string) {
  const arr = str?.trim()?.split(' ');
  if (arr?.length) {
    return arr[0];
  }
  return "";
}

export default function SideNav(props: any) {
  const nav = useNavigate();
  const transjwt: any = useSelector((state: any) => state.Parent.transjwt);
  const students = useSelector((state: any) => state.Student.students);
  const guardian = useSelector((state: any) => state.Parent.guardian);
  const dispatch: any = useDispatch();
  const [isOpenStudentsMenu, setIsOpenStudentsMenu] = useState(false);

  useEffect(() => {
    dispatch(actionCreators.GetStudents({ id: guardian.id }));
  }, []);

  let t = new Translate();
  // const subMenu = props.Menu.MenuItems.map((m:MenuItem) => {
  //         return(
  //             getMenu(m)
  //         );
  // });
  {
    /*<NavLink  className="nav-link" to={"/dashboard/users"} key ="1"> Users</NavLink>,*/
  }
  const [studentsDropDownFlag, setStudentsDropDownFlag] = useState(false);
  const pages = [
    <NavLink key="2" className="nav-link" to={"/dashboard/dopayment"}>
      Do Payments
    </NavLink>,
    <NavLink key="3" className="nav-link" to={"/dashboard/payments"}>
      Payments
    </NavLink>,
    <NavLink key="4" className="nav-link" to={"/dashboard/subscriptions"}>
      Subscriptions{" "}
    </NavLink>,
  ];

  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark navbar-header"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <Nav className="nav">
            <NavLink
              key="10"
              className="nav-link fa fa-home"
              to={"/dashboard/home"}
            >
              {/* &nbsp; Home{" "} */}
              &nbsp;
              {t.translate("Home")}
            </NavLink>

            {/* <DropDownNav key="1" name="Payments" className="fa fa-credit-card">


              {pages}
            </DropDownNav>*/}

            {/* <NavLink
              key="8"
              className="nav-link fa fa-money"
              to={"/dashboard/ewallet"}
            >
          
              &nbsp; {" "}
              {t.translate("Smart E-Wallet")}

            </NavLink> */}

            <React.Fragment>
              <div className="dropdown-container">
                <div
                  className=" d-flex align-items-baseline justify-content-between "
                >
                  <NavLink
                    key="7"
                    className="nav-link fa fa-users"
                    to={"/dashboard/viewstudents"}
                  >
                    &nbsp; {" "}
                    {t.translate("Students")}

                  </NavLink>
                  <div
                    className="  pr-3 "
                    id={`toggler-students`}
                    style={{
                      width: "fit-content",
                      fontSize: "11pt",
                      textAlign: "start",
                      background: "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <i className={"fa fa-solid fa-chevron-right"}
                      style={{
                        transition: 'transform 500ms ease-in-out',
                        transform: isOpenStudentsMenu ? 'rotate(-90deg)' : 'rotate(90deg)',
                      }}
                    ></i>
                  </div>
                </div>
                <UncontrolledCollapse
                  toggler={`#toggler-students`}
                  onEntering={() => setIsOpenStudentsMenu(true)}
                  onEntered={() => {
                  }}
                  onExiting={() => setIsOpenStudentsMenu(false)}
                  onExited={() => {
                  }}
                >
                  {students?.map((student: any, key: any) => {

                    return (
                      <React.Fragment key={key}>

                        <NavLink
                          title={student.fullname}
                          key={`${student.id} - ${student.fullname}`}
                          className="nav-link fa fa-user pl-5 "
                          to={`/dashboard/viewstudents/${student.id}`}

                        >
                          &nbsp; {" "}
                          {getFirstWord(student.fullname)}
                        </NavLink>
                      </React.Fragment>
                    );
                  })}
                </UncontrolledCollapse>
              </div>
            </React.Fragment>
            <NavLink
              key="schoolfees"
              className="nav-link fa fa-money"
              to={"/dashboard/finance"}
            >
              &nbsp; {" "}
              {t.translate("Smart Wallet")}

            </NavLink>


            {/* Move this inside Finance Section we can return them again if needed  */}
            {/* <NavLink
              key="schoolfees"
              className="nav-link fa fa-money"
              to={"/dashboard/schoolfees"}
            >
              &nbsp; {" "}
              {t.translate("School Installments")}

            </NavLink>
            <NavLink
              key="schoolfees"
              className="nav-link fa fa-money"
              to={"/dashboard/servicesubscription"}
            >
              &nbsp; {" "}
              {t.translate("Service subscriptions")}

            </NavLink> */}




            <NavLink
              key="19"
              className="nav-link fas fa-clock-o"
              to={"/dashboard/latearrival"}
            >
              &nbsp; {t.translate("Late Arrival")}
            </NavLink>
            <NavLink
              key="19.1"
              className="nav-link fas fa-clock-o"
              to={"/dashboard/earlydismissal"}
            >
              &nbsp; {t.translate("Early Dismissal")}
            </NavLink>
            <NavLink
              key="70"
              className="nav-link fa fa-bed"
              to={"/dashboard/excuses"}
            >
              &nbsp; {t.translate("Excuses")}
            </NavLink>

            <NavLink
              key="20"
              className="nav-link fa fa-comments"
              to={"/dashboard/meetings"}
            >
              &nbsp; {t.translate("Request Meeting")}
            </NavLink>
            <NavLink
              key="groupmeetings"
              className="nav-link fa fa-comments"
              to={"/dashboard/groupmeetings"}
            >
              &nbsp; {" "}
              {t.translate("Group Meetings")}

            </NavLink>
            {/* <NavLink
              key="6"
              className="nav-link fa fa-comments"
              to={"/dashboard/chatlist"}
            >
              &nbsp; {t.translate("Messages")}
            </NavLink> */}
            <NavLink
              key="14"

              className="nav-link fa fa-book"
              to={"/dashboard/academicinterventions"}
            >
              &nbsp; {t.translate("Academic interventions")}
            </NavLink>
            <NavLink
              key="15"
              className="nav-link fa fa-ban"
              to={"/dashboard/interventions"}
            >
              &nbsp; {t.translate("Character Interventions")}
            </NavLink>
            <NavLink
              key="16"
              className="nav-link fa fa-stethoscope"
              to={"/dashboard/clinicvisits"}
            >
              &nbsp; {t.translate("Clinic Visits")}
            </NavLink>

            <NavLink
              key="30"
              className="nav-link fas fa-heart text-danger"
              to={"/dashboard/WallOfLove"}
            >
              <span className="text-white"> &nbsp; {t.translate("Wall of Love")}</span>
            </NavLink>

            <NavLink
              key="57"
              className="nav-link fa fa-apple"
              to={"/dashboard/cafeteria"}
            >
              {/* &nbsp; Cafeteria{" "} */}
              &nbsp; {t.translate("Cafeteria")}
            </NavLink>
            <a
              key="4"
              target="blank"
              className="nav-link fa fa-bus"
              href={`https://transportation.ivyis.org/?hash=` + transjwt}
            >
              &nbsp; {t.translate("Transportation")}
            </a>

            {/*             <div className="sb-sidenav-menu-heading">Addons</div>
            <NavLink className="nav-link" to="charts.html">
              <div className="sb-nav-link-icon"><i className="fa fa-area-chart"></i></div>
            Charts
          </NavLink>
            <NavLink className="nav-link" to="tables.html">
              <div className="sb-nav-link-icon"><i className="fa fa-table"></i></div>
            Tables
            </NavLink> */}
          </Nav>
        </div>
      </nav>
    </div>
  );
}
