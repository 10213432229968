import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Translate } from "../../helpers/Translate";
import axios from 'axios';
const t = new Translate();

const nameMapped: any = {
    dashboard: "Dashboard",
    home: "Home",
    ewallet: "Smart E-Wallet",
    groupmeetings: "Group Meetings",
    viewstudents: "Students",
    schoolfees: "School Installments",
    servicesubscription: "Service subscriptions",
    finance: "Smart Wallet",
    latearrival: "Late Arrival",
    earlydismissal: "Early Dismissal",
    excuses: "Excuses",
    meetings: "Meetings",
    academicinterventions: "Academic Interventions",
    interventions: "Character Interventions",
    clinicvisits: "Clinic Visits",
    WallOfLove: "Wall Of Love",
    cafeteria: "Cafeteria",
    resturant: "Resturant",
    transaction: "Transaction",
    payments: "Payments",
    subscriptions: "Subscriptions",
    installments: "Installments",
    parentmenu: "Parent Menu",
    profilemenu: "Profile Menu",
    changepassword: "Change Password",
    editprofile: "Edit Profile",
    viewprofile: "View Profile",
    chatcontent: "Chat Content",
    chatlist: "Chat List",
    chatbody: "Chat Body",
    dopayment: "Do Payment",
    classactivities: "Class Activities",
    cafeteriamenu: "Cafeteria Menu",
    "Reinforcement-point": "Reinforcement Point",
    "progress-report": "Progress Report",
    "map-growth": "Map Growth",
}

const Breadcrumbs = () => {
    const location = useLocation();
    const path = location?.pathname;
    const pathnames = path?.split('/')?.filter(x => x);
    const currentPage = pathnames?.length ? (pathnames[pathnames?.length - 1] ?? "") : "";
    const [currentPageName, setCurrentPageName] = useState(currentPage);
    useEffect(() => {
        // console.log("a");
        if (!pathnames || (!pathnames?.length)) {
            return;
        }
        // console.log("b");
        if (nameMapped?.hasOwnProperty(currentPage)) {
            // console.log("c1");
            setCurrentPageName(nameMapped[currentPage]);
        } else {
            // console.log("c2");
            if (1 > pathnames?.length) {
                return;
            }
            const parent = pathnames[pathnames?.length - 2];
            if (!parent) {
                return;
            }
            axios.get((process.env.REACT_APP_BASEURL) + `get_breadcrumbs.php?path=${path}&curr=${currentPage}`)?.then((response: any) => {
                const res_data_data = response?.data?.data
                if (!res_data_data) {
                    return;
                }
                setCurrentPageName(res_data_data);
                nameMapped[currentPage] = res_data_data;
            })
        }

    }, [location]);
    if (!pathnames || (!pathnames?.length)) {
        return null;
    }

    return (
        <div>

            <h1 className="mt-4">{t.translate(currentPageName)}</h1>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {/* <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                    </li> */}
                    {pathnames.map((value, index) => {
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        return (
                            <li key={to} className="breadcrumb-item">
                                {/* <Link to={to}>{t.translate(value)}</Link> */}
                                <Link to={to}>{t.translate(nameMapped?.hasOwnProperty(value) ? nameMapped[value] : value)}</Link>
                            </li>
                        );
                    })}
                </ol>
            </nav>
        </div>
    );

}

export default Breadcrumbs