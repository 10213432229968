import { Link } from 'react-router-dom';

import React, {  useEffect, useState } from "react";

import '../../../css/login.css';


import avatar from '../../../assets/avatar.jpeg';
import axios from 'axios';
import { url } from '../../../store/initialStatus';
import Cookies from 'js-cookie';
import { parseJwt } from '../../../helpers/func';








const ViewProfile = (props :any)=>{
    const [state , setState] = useState({
        firstname: "",
        middlename: "",
        lastname: "",
        username: "",
        ssn: "",
        ocupation: "",
        country: "",
        mobile: "",
        driveid :"",
        education_level :"",
        countryName: null,
        picture: [],
        selectedCountry: "EGY",
        phone: "Must be Mobile Number ex: 0123456789101"
    });


     
    useEffect(()=>{
        const token :any = Cookies.get("guardiantoken");
        let parsedToken  = parseJwt(token);
        let guardian = parsedToken.data.guardian 
        if(guardian.id) {
            axios.post(`${url}guardian/getguardian.php` , {id :guardian?.id}).then((res :any)=>{
                setState(res.data.data)
            })
        }
    },[])






    return (
       
            <>

                <div className="row">
                    <div id="user-profile" className="col-12">
                        <div className="profile-with-cover card">
                            <div className="card-img-top img-fluid bg-cover height-30 50% center image"

                            >
                            </div><br />
                            <div className="media profil-cover-details row">
                                <div className="col-5">
                                    <div className="align-self-start halfway-fab pl-3 pt-2">
                                        <div className="text-left">
                                            <button type="button" className="btn btn-lg btn-primary" disabled>Profile Information </button>
                                            <h3 className="card-title2
                                           nav-link">
                                                {state.firstname} &nbsp;
                                                {state.middlename} &nbsp;
                                                {state.lastname} &nbsp;
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="align-self-center halfway-fab text-center">
                                        <a className="profile-image">
                                            <img src={state.driveid ? state.driveid.includes("https://") ? state.driveid : `https://drive.google.com/uc?id=${state.driveid}&export=download` : `${avatar}`}
                                                className="rounded-circle" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="media profil-cover-details row">
                                <div className="card-body" >
                                    <Link to="/dashboard/editprofile"
                                        className="btn btn-success float-right"
                                        type="button" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit">&nbsp;Edit Profile</i></Link>
                                    <br /><br />
                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Name:
                                                </td>
                                                <td>
                                                    {state.firstname} &nbsp;
                                                    {state.middlename} &nbsp;
                                                    {state.lastname} &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    National ID / SSN :
                                                </td>
                                                <td>
                                                    {state.ssn}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Email :
                                                </td>
                                                <td>
                                                    {state.username}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Occupation :
                                                </td>
                                                <td>
                                                    {state.ocupation}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    Country :
                                                </td>
                                                <td>
                                                    {state.country}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    Mobile :
                                                </td>
                                                <td>
                                                    {state.mobile}
                                                </td>

                                            </tr>

                                            <tr>
                                                <td>
                                                    Education Level:
                                                </td>
                                                <td>
                                                    {state.education_level}
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>

       
    )
   

}




export default ViewProfile;

